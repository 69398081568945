import { graphql } from 'gatsby';
import React from 'react';
import HelpArticleContent from '../components/HelpCenter/HelpArticleContent';
import GeneralHead from '../components/generalHead';
import Layout from '../components/layout';
import SEO from '../components/seo';

const HelpArticle = ({ data: { articleAuthor, helpArticle } }) => (
  <Layout forceShowHeader forceShowFooter>
    <HelpArticleContent
      articleAuthor={articleAuthor}
      helpArticle={helpArticle}
    />
  </Layout>
);

export const Head = ({
  data: {
    helpArticle: { parent, description, title },
  },
}) => (
  <GeneralHead>
    <title>{title} | Shopmonkey</title>
    <meta name="description" content={description} />
    <SEO indexable={!!parent} />
  </GeneralHead>
);

export const query = graphql`
  query($authorId: String!, $id: String!) {
    articleAuthor: helpCenterAuthor(id: { eq: $authorId }) {
      ...helpCenterAuthor
    }
    helpArticle: helpCenterArticle(id: { eq: $id }) {
      ...helpCenterArticle
      parent {
        ... on HelpCenterCollection {
          ...helpCenterCollection
          internal {
            type
          }
        }
        ... on HelpCenterSection {
          ...helpCenterSection
          internal {
            type
          }
          parent {
            ... on HelpCenterCollection {
              ...helpCenterCollection
              internal {
                type
              }
            }
          }
        }
      }
    }
  }
`;

export default HelpArticle;
